export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',
    currency: '',

    // Ianos IANOS PLATFORM UAT
    firebase: {
        apiKey: "AIzaSyA--I9LZbUEkPlzMxjXXnSgEVsTxs3eKzs",
        authDomain: "ianos-app-platform-uat.firebaseapp.com",
        projectId: "ianos-app-platform-uat",
        storageBucket: "ianos-app-platform-uat.appspot.com",
        messagingSenderId: "464608022096",
        appId: "1:464608022096:web:5f49f4e4e779fe9ef84b55",
        measurementId: "G-4T268Q7389"
    },
    googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
    urlBase: 'https://us-central1-ianos-app-platform-uat.cloudfunctions.net',
    apikey: 'f7aa1edfa9f-52ef629ef8f50-40ab-42faf4fe',
    urlBaseWompy: 'https://us-central1-ianos-app-platform-uat.cloudfunctions.net/Wompi',

    // TWILIO CREDENCIALES IanOS DEV
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '13b6af0768f7255a066f3436df02f6ca',
        TWILIO_ACCOUNT_SID: 'ACb5f6530b25d1d3d182d2df34d1747117',
        TWILIO_SERVICE_TOKEN: 'VA6f37e2f3e25ae98433a02926ec75c77a',
        TWILIO_MESSAGING_SERVICE_SID: 'MG7302002067524cd572d66063ff774b61'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    mailchimp: {
        apiKey: '4d1b5faa7e6c99414d58feb265309a36-us22',
        server: 'us22',
        listUniqueId: 'd5e6af4f94'
    }
};

